import React from "react"

import "./videoModal.scss"

const VideoModal = ({ videoId, onClose }) => {
  function closeOnClick(evt) {
    if (evt.currentTarget === evt.target) onClose()
  }
  return (
    <div className="videoModal" onClick={closeOnClick}>
      <iframe
        className="videoModal--video"
        src={`https://player.vimeo.com/video/${videoId}`}
        width="1024"
        height="576"
        frameborder="0"
        allowFullScreen="true"
        webkitallowfullscreen="true"
        mozallowfullscreen="true"
      ></iframe>
    </div>
  )
}

export default VideoModal
