import React from "react"
import useMeasure from "react-use-measure"
import { getVideoThumbnail } from "../assets"

export const VideoThumbnail = ({ video }) => {
  const [ref, { width, height }] = useMeasure()
  let { url, imgWidth, imgHeight, imgScale, xPos, yPos } =
    getVideoThumbnail(video.id) ?? {}

  let scale = imgScale * (height / imgHeight)
  let translateX =
    typeof xPos === "number" ? -xPos : (width / scale - imgWidth) / 2
  let translateY =
    typeof yPos === "number" ? -yPos : (height / scale - imgHeight) / 2

  return (
    <div
      className="videoThumbnail"
      ref={ref}
      style={{ overflow: "hidden", backgroundColor: "black" }}
    >
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: imgWidth,
          height: imgHeight,
          backgroundImage: `url(${url})`,
          backgroundSize: `cover`,
          backgroundRepeat: "no-repeat",
          transformOrigin: "top left",
          transform: ` scale(${scale}) translate(${translateX}px, ${translateY}px)`,
        }}
      ></div>
    </div>
  )
}
