export function isSafari() {
  return (
    navigator.vendor &&
    navigator.vendor.indexOf("Apple") > -1 &&
    navigator.userAgent &&
    navigator.userAgent.indexOf("CriOS") == -1 &&
    navigator.userAgent.indexOf("FxiOS") == -1
  )
}

export async function workaroundOverscrollPrevention(element) {
  await new Promise(resolve => window.requestAnimationFrame(resolve))
  let { scrollTop, scrollLeft, scrollHeight, clientHeight } = element
  let atTop = scrollTop === 0
  let beforeTop = 1
  let atBottom = scrollTop === scrollHeight - clientHeight
  let beforeBottom = scrollHeight - clientHeight - 1
  if (atTop) {
    element.scrollTo(scrollLeft, beforeTop)
  } else if (atBottom) {
    element.scrollTo(scrollLeft, beforeBottom)
  }
}
